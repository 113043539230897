import React, {useEffect} from "react";

import Seo from "../components/seo/index";

import Layout from "../layout/index";
import HomePage from "./home";
import { lStorage } from "../components/utils/storage";
import KanhaRunService from "../components/services/kanha.service";
import { toast, ToastContainer } from "react-toastify";

// import Bannerheartfulness from "../components/home/banner-heartfulness";
// markup
const IndexPage = () => {
  const kanhaApi = new KanhaRunService();

  useEffect(() =>{
    lStorage.set("globalRun",1);
    const isSpecialDonation =  lStorage.get("specialDonation");
    if(isSpecialDonation === undefined || null){
      lStorage.set("specialDonation", "NO");
    }
  },[])

  const localtionDetails =
  typeof window !== "undefined" && window.location
    ? document.location.search
    : "";

  useEffect(() => {
    if (localtionDetails) {
      let params = new URLSearchParams(localtionDetails);
      let company_code = params.get("company_code");

      if (company_code) {
        kanhaApi
          .checkValidCompanyCode(company_code)
          .then((response) => {
            if (
              response.data.isError === false ||
              response.data.statusCode === "200"
            ) {
              if (response.data.data.length >= 1) {
                lStorage.set("companyCodeDetails", response.data.data);
              } else if (response.data.data.length === 0) {
                toast.error("Not a valid company");
              }
            }
          })
          .catch((err) => {
            toast.error(err);
          });
      }
    }
  }, [localtionDetails]);

  return (
    <div>
      <Seo title="Green Heartfulness Run 2024 - Home" />
      <Layout isSticky>
        <HomePage />
      </Layout>
      {localtionDetails && <ToastContainer
        position="top-right"
        autoClose={3000}
      />}
    </div>
  );
};

export default IndexPage;
